import React from 'react'
import { AddAPhotoOutlined, MusicNoteOutlined } from '@mui/icons-material'
import { Button } from 'antd'
import './musicianImage.css'

const MusicianImage = ({ editMode }: { editMode: boolean }) => {
  const [isAudioPlaying, setIsAudioPlaying] = React.useState(false)
  const [image, setImage] = React.useState('')
  const [audio, setAudio] = React.useState('')

  const imageInputRef = React.useRef<HTMLInputElement>(null)
  const audioInputRef = React.useRef<HTMLInputElement>(null)

  const audioRef = React.useRef(
    new Audio(audio ? audio : 'https://storage.googleapis.com/demo-profile/audio/Wartime%204.3.m4a'),
  )

  const handlePlayAudio = () => {
    const audio = audioRef.current
    if (isAudioPlaying) {
      audio.pause()
    } else {
      audio.play()
    }
    setIsAudioPlaying(!isAudioPlaying)
  }

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        if (event.target?.result) {
          setImage(event.target.result.toString())
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const handleUploadAudio = (event: any) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        if (event.target?.result) {
          setAudio(event.target.result.toString())
        }
      }
      reader.readAsDataURL(file)
    }
  }

  React.useEffect(() => {
    if (audio) {
      audioRef.current.src = audio
    }
  }, [audio])

  return (
    <div className="relative flex justify-center">
      <img
        className={`${isAudioPlaying ? 'musician-image-animating' : 'musician-image'} w-[80vw] h-[80vw] lg:w-[45vh] lg:h-[45vh] lg:min-w-[250px] lg:min-h-[250px] lg:max-w-[450px] lg:max-h-[450px]`}
        onClick={() => !editMode && handlePlayAudio()}
        src={image ? image : 'https://storage.googleapis.com/demo-profile/images/the-musician-unblurred.png'}
        alt="musician"
      />
      {editMode ? (
        <div className="upload-buttons">
          <input
            onChange={handleUploadAudio}
            multiple={false}
            ref={audioInputRef}
            type="file"
            accept="audio/*"
            hidden
          />

          <Button className="upload-file" onClick={() => audioInputRef.current?.click()} style={{ color: '#000000' }}>
            <MusicNoteOutlined />
          </Button>

          <input
            onChange={handleUploadImage}
            multiple={false}
            ref={imageInputRef}
            type="file"
            accept="image/*"
            hidden
          />

          <Button className="upload-file" onClick={() => imageInputRef.current?.click()} style={{ color: '#000000' }}>
            <AddAPhotoOutlined />
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default MusicianImage
