import React from 'react'
import { Done } from '@mui/icons-material'
import { Input, Modal } from 'antd'
import './musicianAbout.css'

const { TextArea } = Input

const MusicianAbout = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [aboutText, setAboutText] = React.useState(
    'Born in Kufor Nimeh. As a child I trained in the Edward Said Conservatory of Music playing the Buzoq. Now I am furthering my studies of music teaching by taking the ESNCM music diplome course. I enjoy teaching and performing Arabic music.',
  )
  const [offerings, setOfferings] = React.useState(['Performance', 'Teaching'])

  const allOfferings = ['Performance', 'Teaching', 'Composition', 'Arrangement', 'Production']

  const setOffering = (offering: string) => {
    if (offerings.includes(offering)) {
      setOfferings(offerings.filter((value) => value != offering))
    } else {
      setOfferings([...offerings, offering])
    }
  }

  const isOfferingSelected = (offering: string): boolean => {
    return offerings.includes(offering)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal centered open={isModalOpen} closable={false} footer={[]}>
        <div className="flex flex-col items-center w-full">
          <p className="title-small mb-0">About</p>
          <TextArea
            autoSize={{ minRows: 4, maxRows: 6 }}
            variant="borderless"
            className="text-area"
            placeholder="More information about my work or myself"
            value={aboutText}
            onChange={(e) => setAboutText(e.target.value)}
            maxLength={300}
          />
          <p className="title-small">Offerings</p>
          <div className="offerings-pills">
            {allOfferings.map((value, index) => (
              <p
                className={`offerings-pill ${isOfferingSelected(value) ? 'highlight-offering' : null}`}
                key={index}
                onClick={() => setOffering(value)}
              >
                {value}
              </p>
            ))}
          </div>
          <button
            disabled={aboutText.length == 0 ? true : false}
            className={`flex p-0 mt-2.5 justify-center items-center size-12 rounded-full bg-[#d9d9d9] ${aboutText.length == 0 ? 'cursor-no-drop' : 'cursor-pointer'}`}
            onClick={handleOk}
          >
            <Done />
          </button>
        </div>
      </Modal>
      <div
        className="musician-about-card lg:w-[35vw] lg:min-w-[440px] lg:max-h-[340px] flex flex-col items-center lg:pb-0 pb-[4vh] mx-4 lg:mx-0"
        onClick={() => (editMode ? showModal() : null)}
      >
        <p className="title-small mb-0 py-[5px]">About</p>
        <p className="musician-about-text">{aboutText}</p>
        {offerings.length == 0 ? null : (
          <>
            <p className="title-small pb-[5px]">Offerings</p>
            <div className="offerings-pills">
              {offerings.map((value, index) => (
                <p className="offerings-pill" key={index}>
                  {value}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MusicianAbout
