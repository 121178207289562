import React from 'react'
import './musicianLocation.css'
import EditLocationModal from './editLocationModal'

const MusicianLocation = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [musicianLocation, setMusicianLocation] = React.useState<string[]>(['Ramallah', 'Palestine'])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (location: string[]) => {
    setMusicianLocation(location)
    setIsModalOpen(false)
  }

  return (
    <div
      className={`${editMode ? 'cursor-pointer' : ''} musician-location-wrapper lg:justify-start justify-center`}
      onClick={() => (editMode && !isModalOpen ? showModal() : null)}
    >
      {musicianLocation && musicianLocation.length > 0 ? (
        <>
          {musicianLocation.length >= 3 ? (
            <div className="musician-location">
              <p className="location-name">
                {musicianLocation[0]}, {musicianLocation[1]},
              </p>
              <p className="location-name">{musicianLocation[2] == 'Israel' ? 'Palestine' : musicianLocation[2]}</p>
            </div>
          ) : (
            <div className="musician-location lg:mt-0 mt-[2vh]">
              <p className="location-name lg:text-start text-center">
                {' '}
                {musicianLocation[0] && `${musicianLocation[0]},`}
              </p>
              <p className="location-name lg:text-start text-center">
                {musicianLocation[1] == 'Israel' || (!musicianLocation[1] && musicianLocation[0])
                  ? 'Palestine'
                  : musicianLocation[1]}
              </p>
            </div>
          )}
        </>
      ) : (
        <p>Enter location</p>
      )}

      <EditLocationModal isModalOpen={isModalOpen} handleOk={handleOk} />
    </div>
  )
}

export default MusicianLocation
