import React from 'react'

import './musicianGenres.css'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Input, Modal } from 'antd'
import { Done } from '@mui/icons-material'

const MusicianGenres = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [genre, setGenre] = React.useState('')

  const [genres, setGenres] = React.useState([
    'Arabic',
    'Classical Arabic',
    'Modern Arabic',
    'Arabic Improvisation',
    'Traditional Palestinian',
  ])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleRemoveGenre = (selectedGenre: string) => {
    const updatedGenres = genres.filter((genre) => genre != selectedGenre)
    setGenres(updatedGenres)
  }

  const handleSubmit = () => {
    if (genre && !genres.includes(genre)) {
      setGenres([...genres, genre])
    }
    setGenre('')
    setIsModalOpen(false)
  }

  const EditGenresModal = (
    <Modal centered open={isModalOpen} closable={false} footer={[]}>
      <div className="flex flex-col items-center">
        <Input
          variant="borderless"
          className="text-input"
          value={genre}
          placeholder="Genre"
          onChange={(e) => setGenre(e.target.value)}
        />
        <button className="musician-genres-done-button" onClick={handleSubmit}>
          <Done />
        </button>
      </div>
    </Modal>
  )

  return (
    <div
      className={`genres-card mb-0 flex flex-col items-center mx-4 lg:mx-0 lg:pb-0 pb-[4vh] ${editMode ? 'cursor-pointer' : ''}`}
    >
      <p
        className="py-[5px] title-small"
        onClick={() => setGenres(['Jazz', 'Pianist', 'Buzuqist', 'Guitar', 'Drums', 'Vocalist'])}
      >
        Genres
      </p>
      <div className="genres-pills gap-2">
        <>
          {genres.map((genre, index) => (
            <p key={index} className="genre">
              {genre}
              {editMode ? <MinusCircleOutlined onClick={() => handleRemoveGenre(genre)} /> : null}
            </p>
          ))}

          {editMode ? (
            <p className="genre cursor-pointer" onClick={showModal}>
              <PlusOutlined />
            </p>
          ) : null}
        </>
      </div>
      {EditGenresModal}
    </div>
  )
}

export default MusicianGenres
