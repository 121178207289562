import React from 'react'
import { Done, Message } from '@mui/icons-material'
import { Input, Modal } from 'antd'
import './musicianContactForm.css'

const { TextArea } = Input

const MusicianContactForm = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal centered open={isModalOpen} closable={false} footer={[]}>
        <div className="flex flex-col items-center w-full">
          <Input variant="borderless" className="text-input" placeholder="Your Email" />
          <TextArea
            autoSize={{ minRows: 4, maxRows: 10 }}
            variant="borderless"
            className="text-area"
            placeholder="Your message to the musician"
          />
          <button
            className="flex p-0 mt-2.5 justify-center items-center size-12 rounded-full bg-[#d9d9d9]"
            onClick={handleOk}
          >
            <Done />
          </button>
        </div>
      </Modal>
      {editMode ? null : (
        <button
          className="musician-contact-form-button lg:absolute lg:bottom-[5px] lg:right-[60px]"
          onClick={showModal}
        >
          <Message />
        </button>
      )}
    </>
  )
}

export default MusicianContactForm
