import React from 'react'
import './musicianPage.css'
import MusicianImage from './MusicianImage/musicianImage'
import MusicianSkills from './MusicianSkills/musicianSkills'
import { Edit, Done, Image } from '@mui/icons-material'
import MusicianLocation from './MusicianLocation/musicianLocation'
import MusicianSocialLinks from './MusicianSocialLinks/musicianSocialLinks'
import MusicianName from './MusicianName/musicianName'
import MusicianContactForm from './MusicianContactForm/musicianContactForm'
import MusicianAbout from './MusicianAbout/musicianAbout'
import MusicianGenres from './MusicianGenres/musicianGenres'
import MusicianPortfolio from './MusicianPortfolio/musicianPortfolio'

const MusicianPage = () => {
  const [editMode, setEditMode] = React.useState(false)
  const [backgroundImageIndex, setBackgroundImageIndex] = React.useState(0)

  const changeBackgroundImageIndex = () => {
    setBackgroundImageIndex((backgroundImageIndex + 1) % 3)
  }

  return (
    <div className="lg:h-full">
      <div
        className={`flex flex-col relative bg-no-repeat	bg-cover h-full bg-black lg:hidden musician-mobile-background-${backgroundImageIndex}`}
      >
        <button
          className="flex absolute items-center justify-center top-2.5 right-2.5 rounded-full bg-[#d9d9d9] hover:bg-[#d9d9d9]/90 size-14 cursor-pointer [&>svg]:size-7"
          onClick={() => setEditMode(!editMode)}
        >
          {editMode ? <Done /> : <Edit />}
        </button>
        {editMode ? (
          <button
            className="flex absolute items-center justify-center size-12 top-4 right-20 rounded-full bg-[#d9d9d9] hover:bg-[#d9d9d9]/90 cursor-pointer"
            onClick={changeBackgroundImageIndex}
          >
            <Image />
          </button>
        ) : null}

        <div className="flex flex-col pt-[8vh]">
          <MusicianImage editMode={editMode} />
          <MusicianSkills editMode={editMode} />
          <MusicianName editMode={editMode} />
          <MusicianLocation editMode={editMode} />
          <div className="pt-[2vh] flex flex-row justify-center">
            <MusicianContactForm editMode={editMode} />
            <MusicianSocialLinks editMode={editMode} />
          </div>
          <div className="flex flex-row justify-center pt-[4vh] px-[2vw]">
            <MusicianAbout editMode={editMode} />
          </div>
          <div className="flex flex-row justify-center pt-[4vh] px-[2vw]">
            <MusicianGenres editMode={editMode} />
          </div>
          <div className="flex flex-row justify-center pt-[4vh] px-[2vw]">
            <MusicianPortfolio editMode={editMode} />
          </div>
        </div>
      </div>
      {/* Desktop below mobile above */}
      <div
        className={`hidden lg:flex lg:flex-col relative bg-no-repeat	bg-cover h-full bg-black min-w-[1180px] min-h-[820px] musician-page-background-${backgroundImageIndex}`}
      >
        <button
          className="flex absolute items-center justify-center top-2.5 right-2.5 rounded-full bg-[#d9d9d9] hover:bg-[#d9d9d9]/90 size-14 cursor-pointer [&>svg]:size-7"
          onClick={() => setEditMode(!editMode)}
        >
          {editMode ? <Done /> : <Edit />}
        </button>
        {editMode ? (
          <button
            className="flex absolute items-center justify-center size-12 top-4 right-20 rounded-full bg-[#d9d9d9] hover:bg-[#d9d9d9]/90 cursor-pointer"
            onClick={changeBackgroundImageIndex}
          >
            <Image />
          </button>
        ) : null}
        <div className="px-20 pt-3 h-full">
          <div className="flex flex-row">
            <div className="w-1/4 flex flex-col justify-end">
              <MusicianName editMode={editMode} />
            </div>
            <div className="w-1/2">
              <MusicianImage editMode={editMode} />
            </div>
            <div className="w-1/4" />
          </div>
          <div className="flex flex-row">
            <div className="w-1/4">
              <MusicianLocation editMode={editMode} />
            </div>
            <div className="w-1/2">
              <MusicianSkills editMode={editMode} />
            </div>
            <div className="w-1/4 relative">
              <MusicianContactForm editMode={editMode} />
              <MusicianSocialLinks editMode={editMode} />
            </div>
          </div>
        </div>
        <div className="px-20 pt-14 h-full">
          <div className="flex flex-row h-full">
            <div className="w-1/4 flex flex-row h-full">
              <MusicianGenres editMode={editMode} />
            </div>
            <div className="w-1/2 flex flex-row justify-center h-full">
              <MusicianAbout editMode={editMode} />
            </div>
            <div className="w-1/4 flex flex-row h-full">
              <MusicianPortfolio editMode={editMode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MusicianPage
