import React from 'react'
import { ReactComponent as LirariLogo } from './../../assets/images/lirari-lyra.svg'
import { ReactComponent as Arrows } from './../../assets/images/arrows.svg'

const LandingPage = () => {
  return (
    <div className="bg-black flex flex-col h-[100vh] lg:h-full">
      <div className="flex flex-row justify-center items-center h-[22vh] lg:h-1/4 border-b-[1px] border-white">
        <div className="flex flex-col justify-center items-center">
          <p className="text-[55px] lg:text-[70px] text-white m-0">lirari</p>
          <p className="text-[20px] lg:text-[25px] text-white m-0">The Professional</p>
          <p className="text-[20px] lg:text-[25px] text-white m-0">Musician Network</p>
        </div>
        <LirariLogo className="lg:w-[119px] lg:h-[236px] w-[80.68px] h-[160px] fill-white" />
      </div>
      <div className="flex flex-col lg:flex-row h-[68vh] lg:h-1/2 justify-around items-center">
        <div className="flex flex-col items-center h-1/2 justify-around lg:w-2/5">
          <p className="lg:text-[40px] text-[25px] text-center">Are you a Musician?</p>
          <p className="lg:text-[20px] text-[16px] text-center">Create a free and public professional profile</p>
          <p className="lg:text-[20px] text-[16px] text-center">List your current location</p>
          <p className="lg:text-[20px] text-[16px] text-center">Receive work opportunities</p>
        </div>
        <div className="lg:w-[249px] lg:h-[90px] lg:rotate-0 lg:w-1/5">
          <Arrows />
        </div>
        <div className="flex flex-col items-center h-1/2 justify-around lg:w-2/5">
          <p className="lg:text-[40px] text-[25px] text-center">Looking for Musicians?</p>
          <p className="lg:text-[20px] text-[16px] text-center">Network of vetted musicians</p>
          <p className="lg:text-[20px] text-[16px] text-center">Search based on genre, type of work, and location</p>
          <p className="lg:text-[20px] text-[16px] text-center">Reach out directly musicians to arrange work</p>
        </div>
      </div>
      <div className="flex flex-row h-min h-[10vh] lg:h-1/4 justify-center items-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfM0NZi31Yw2mhEb71amYOk8JBdkUdnitWSMZJIeYYzxU-n-w/viewform?usp=sf_link"
          className="w-[211px] h-[63.14px] bg-[#D9D9D9] text-inherit lg:text-[50px] text-[32px] text-center justify-center items-center flex"
        >
          Join
        </a>
      </div>
    </div>
  )
}

export default LandingPage

// width="442" height="800"
