import React from 'react'
import { Done } from '@mui/icons-material'
import { Modal } from 'antd'
import CustomInput from '../../../Primitives/Input'

const MusicianSkills = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [skills, setSkills] = React.useState(['Pianist', 'Buzuqist', 'Singer'])
  const [skill1, setSkill1] = React.useState('Pianist')
  const [skill2, setSkill2] = React.useState('Buzuqist')
  const [skill3, setSkill3] = React.useState('Singer')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    const list = []
    if (skill1.length > 0) {
      list.push(skill1)
    }
    if (skill2.length > 0) {
      list.push(skill2)
    }
    if (skill3.length > 0) {
      list.push(skill3)
    }
    setSkills(list)
    setIsModalOpen(false)
  }

  const skillsModal = (
    <Modal centered open={isModalOpen} closable={false} footer={[]}>
      <div className="flex flex-col items-center">
        <CustomInput value={skill1} placeholder="Skill 1" onChange={(e) => setSkill1(e.target.value)} />
        <CustomInput value={skill2} placeholder="Skill 2" onChange={(e) => setSkill2(e.target.value)} />
        <CustomInput value={skill3} placeholder="Skill 3" onChange={(e) => setSkill3(e.target.value)} />
        <button
          className="flex p-0 mt-2.5 justify-center items-center size-12 rounded-full bg-[#d9d9d9]"
          onClick={handleOk}
        >
          <Done />
        </button>
      </div>
    </Modal>
  )

  switch (skills.length) {
    case 0:
      return (
        <div
          className={`flex justify-center items-center max-w-[250px] m-auto min-h-12 text-white [&:nth-child(1)]:text-center text-lg lg:text-[22px] ${editMode ? 'cursor-pointer' : ''}`}
          onClick={() => (editMode && !isModalOpen ? showModal() : null)}
        >
          <div className="w-[12vw] max-w-[300px] lg:w-[20vw]">Enter Skills Here</div>
          {skillsModal}
        </div>
      )
    case 1:
      return (
        <div
          className={`flex justify-center items-center max-w-[250px] m-auto min-h-12 text-white [&:nth-child(1)]:text-center text-lg lg:text-[22px] ${editMode ? 'cursor-pointer' : ''}`}
          onClick={() => (editMode && !isModalOpen ? showModal() : null)}
        >
          <div className="w-[12vw] max-w-[200px] lg:w-[20vw]">{skills[0]}</div>
          {skillsModal}
        </div>
      )
    case 2:
      return (
        <div
          className={`flex justify-center items-center max-w-[500px] m-auto min-h-12 text-white text-lg lg:text-[22px] ${editMode ? 'cursor-pointer' : ''}`}
          onClick={() => (editMode && !isModalOpen ? showModal() : null)}
        >
          <div className="w-[12vw] max-w-[200px] lg:w-[20vw] text-end">{skills[0]}</div>
          <div className="size-4 my-0 mx-[1vw] bg-white rounded-full inline-block"></div>
          <div className="w-[12vw] max-w-[200px] lg:w-[20vw] text-start">{skills[1]}</div>
          {skillsModal}
        </div>
      )
    case 3:
      return (
        <div
          className={`flex justify-center items-center text-white max-w-[800px] m-auto min-h-[50px] text-lg lg:text-[22px] ${editMode ? 'cursor-pointer' : ''}`}
          onClick={() => (editMode && !isModalOpen ? showModal() : null)}
        >
          <div className="text-end">{skills[0]}</div>
          <div className="size-4 my-0 mx-[1vw] bg-white rounded-full inline-block"></div>
          <div className="text-center">{skills[1]}</div>
          <div className="size-4 my-0 mx-[1vw] bg-white rounded-full inline-block"></div>
          <div className="text-start">{skills[2]}</div>
          {skillsModal}
        </div>
      )
  }
}

export default MusicianSkills
