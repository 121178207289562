import React from 'react'
import { Done } from '@mui/icons-material'
import { FloatButton, Modal } from 'antd'
import { LinkOutlined, SpotifyFilled, YoutubeFilled } from '@ant-design/icons'
import { ReactComponent as SoundCloud } from './../../../assets/images/soundcloud.svg'
import './musicianSocialLinks.css'
import CustomInput from '../../../Primitives/Input'

const MusicianSocialLinks = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [youtubeLink, setYoutubeLink] = React.useState('https://www.youtube.com/@keyofsea')
  const [spotifyLink, setSpotifyLink] = React.useState('https://open.spotify.com/artist/7iape4TfK8ldPRXy1H0MDJ')
  const [soundcloudLink, setSoundcloudLink] = React.useState('')

  const EditLinksModal = (
    <Modal centered open={isModalOpen} closable={false} footer={[]}>
      <div className="flex flex-col items-center">
        <CustomInput value={youtubeLink} placeholder="Youtube" onChange={(e) => setYoutubeLink(e.target.value)} />
        <CustomInput value={spotifyLink} placeholder="Spotify" onChange={(e) => setSpotifyLink(e.target.value)} />
        <CustomInput
          value={soundcloudLink}
          placeholder="Soundcloud"
          onChange={(e) => setSoundcloudLink(e.target.value)}
        />
        <button
          className="flex p-0 mt-2.5 justify-center items-center min-w-12 min-h-12 rounded-full bg-[#d9d9d9]"
          onClick={() => setIsModalOpen(false)}
        >
          <Done />
        </button>
      </div>
    </Modal>
  )

  return editMode || youtubeLink || spotifyLink || soundcloudLink ? (
    <div onClick={() => (editMode && !isModalOpen ? setIsModalOpen(true) : null)}>
      {!editMode ? (
        <FloatButton.Group
          className="lg:absolute lg:right-0 lg:bottom-1"
          trigger="click"
          type="default"
          icon={<LinkOutlined className="size-6 [&>svg]:size-6" />}
        >
          {youtubeLink ? (
            <a href={youtubeLink} target=" ">
              <FloatButton className="size-12 mb-4" icon={<YoutubeFilled className="size-6 [&>svg]:size-6" />} />
            </a>
          ) : null}

          {spotifyLink ? (
            <a href={spotifyLink} target=" ">
              <FloatButton className="size-12 mb-4" icon={<SpotifyFilled className="size-6 [&>svg]:size-6" />} />
            </a>
          ) : null}

          {soundcloudLink ? (
            <a href={soundcloudLink} target=" ">
              <FloatButton className="size-12 mb-4" icon={<SoundCloud className="size-6 [&>svg]:size-6" />} />
            </a>
          ) : null}
        </FloatButton.Group>
      ) : (
        <a className="lg:absolute lg:right-0 lg:bottom-1">
          <LinkOutlined className="flex p-0 justify-center items-center min-w-[50px] min-h-[50px] size-[50px] rounded-full bg-[#d9d9d9] cursor-pointer [&>svg]:size-6" />
        </a>
      )}

      {EditLinksModal}
    </div>
  ) : null
}

export default MusicianSocialLinks
