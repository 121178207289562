import React from 'react'
import './musicianName.css'
import { Done } from '@mui/icons-material'
import { Input, Modal } from 'antd'

const MusicianName = ({ editMode }: { editMode: boolean }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [firstName, setFirstName] = React.useState('Saad')
  const [lastName, setLastName] = React.useState('Diab')
  const [fontSize, setFontSize] = React.useState(70)

  const calculateFontSize = () => {
    const nameArray = [firstName, lastName]
    const longestName = nameArray.reduce((acc, value) => {
      return acc.length > value.length ? acc : value
    })
    if (longestName.length <= 6) {
      setFontSize(70)
    } else if (longestName.length <= 8) {
      setFontSize(60)
    } else if (longestName.length <= 10) {
      setFontSize(50)
    } else if (longestName.length <= 12) {
      setFontSize(40)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  React.useEffect(() => {
    calculateFontSize()
  }, [firstName, lastName])

  const nameModal = (
    <Modal centered open={isModalOpen} closable={false} footer={[]}>
      <div className="flex flex-col items-center">
        <Input
          variant="borderless"
          className="text-input"
          value={firstName}
          placeholder="First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          variant="borderless"
          className="text-input"
          value={lastName}
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
        <button
          className="flex p-0 mt-2.5 justify-center items-center size-12 rounded-full bg-[#d9d9d9]"
          onClick={() => setIsModalOpen(false)}
        >
          <Done />
        </button>
      </div>
    </Modal>
  )

  return (
    <div
      className={`${editMode ? 'cursor-pointer' : ''} flex flex-col lg:justify-self-end lg:justify-end lg:mb-[6vh] leading-none`}
      onClick={() => (editMode && !isModalOpen ? showModal() : null)}
    >
      <p className="musician-name lg:justify-start justify-center" style={{ fontSize: fontSize }}>
        {firstName ?? 'First Name'}
      </p>
      <div className="flex flex-row items-center lg:justify-start justify-center">
        <p className="musician-name" style={{ fontSize: fontSize }}>
          {lastName ?? 'Last Name'}
        </p>
      </div>
      {nameModal}
    </div>
  )
}

export default MusicianName
