import React from 'react'

import { Divider, Modal, Input } from 'antd'
import ReactPlayer from 'react-player'
import { Spotify } from 'react-spotify-embed'

import { MinusCircleOutlined } from '@ant-design/icons'
import { Done } from '@mui/icons-material'
import { PlusOutlined } from '@ant-design/icons'

const MusicianPortfolioPopup = ({
  popupOpen,
  setPopupOpen,
  videos,
  audios,
  setVideos,
  setAudios,
  editMode,
}: {
  popupOpen: boolean
  setPopupOpen: (value: boolean) => void
  videos: string[]
  audios: string[]
  setVideos: (value: string[]) => void
  setAudios: (value: string[]) => void
  editMode: boolean
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [target, setTarget] = React.useState<'video' | 'audio' | null>(null)
  const [videoToAdd, setVideoToAdd] = React.useState('')
  const [audioToAdd, setAudioToAdd] = React.useState('')

  const isMobile = window.innerWidth <= 768

  const handleRemoveVideo = (selectedVideo: string) => {
    const updatedVideos = videos.filter((video) => video != selectedVideo)
    setVideos(updatedVideos)
  }

  const handleRemoveAudio = (selectedAudio: string) => {
    const updatedAudios = audios.filter((audio) => audio != selectedAudio)
    setVideos(updatedAudios)
  }

  const handleSubmit = () => {
    if (target == 'video') {
      if (videoToAdd && !videos.includes(videoToAdd)) {
        setVideos([...videos, videoToAdd])
      }
    } else if (target == 'audio') {
      if (audioToAdd && !audios.includes(audioToAdd)) {
        setAudios([...audios, audioToAdd])
      }
    }

    setIsModalOpen(false)
  }

  const EditPortfolioModal = (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false)
      }}
      footer={[]}
    >
      <div className="flex flex-col items-center">
        <Input
          variant="borderless"
          className="text-white bg-transparent border-b-white hover:border-b-white focus:border-b-white text-lg text-center hover:bg-transparent focus:bg-transparent placeholder:text-white"
          placeholder={target ? target : ''}
          onChange={(e) => {
            if (target == 'video') {
              setVideoToAdd(e.target.value)
            } else if (target == 'audio') {
              setAudioToAdd(e.target.value)
            }
          }}
        />
        <button
          className="flex items-center justify-center mt-2.5 h-[50px] w-[50px] rounded-full bg-[#d9d9d9]"
          onClick={handleSubmit}
        >
          <Done />
        </button>
      </div>
    </Modal>
  )

  return (
    <Modal
      className="overflow-y-auto h-[90%]"
      width={850}
      centered
      open={popupOpen}
      onCancel={() => setPopupOpen(false)}
      footer={[]}
    >
      <p className="flex text-center font-bold text-2xl mt-0 justify-center">Portfolio</p>
      <div>
        <p className="text-lg mb-0 mt-0 lg:text-xl">Videos</p>
        <Divider />
        <div className="flex flex-col items-center">
          {videos.map((video, index) => (
            <div className="flex space-x-2.5 mb-[50px] text-white w-full lg:w-1/2 h-full" key={index}>
              <ReactPlayer
                controls={true}
                height={isMobile ? '100%' : undefined}
                style={{ width: '100%', height: '100%' }}
                url={video}
              />
              {editMode ? (
                <MinusCircleOutlined
                  className="self-center w-10 h-10 [&>svg]:w-10 [&>svg]:h-10"
                  onClick={() => handleRemoveVideo(video)}
                />
              ) : null}
            </div>
          ))}
        </div>

        {editMode ? (
          <button
            className="flex m-auto w-full h-[180px] lg:w-1/2 cursor-pointer justify-center items-center lg:h-[300px] opacity-50 rounded-2xl bg-white"
            onClick={() => {
              setIsModalOpen(true)
              setTarget('video')
            }}
          >
            <PlusOutlined className="[&>svg]:w-12 [&>svg]:h-12" />
          </button>
        ) : null}

        <p className="text-lg mb-0 mt-0 lg:text-xl">Audios</p>

        <Divider />

        <div className="flex flex-col lg:grid grid-cols-2 gap-12 justify-center">
          {audios.map((audio, index) => (
            <div key={index} className="flex text-white gap-2.5">
              {audio.includes('spotify') ? (
                <Spotify link={audio} height={155} />
              ) : (
                <ReactPlayer width={300} height={155} url={audio} />
              )}
              {editMode ? (
                <MinusCircleOutlined
                  className="self-center w-8 h-8 [&>svg]:w-8 [&>svg]:h-8"
                  onClick={() => handleRemoveAudio(audio)}
                />
              ) : null}
            </div>
          ))}
          {editMode ? (
            <button
              className="w-[300px] h-[155px] opacity-50 rounded-2xl bg-white"
              onClick={() => {
                setIsModalOpen(true)
                setTarget('audio')
              }}
            >
              <PlusOutlined className="[&>svg]:w-6 [&>svg]:h-6" />
            </button>
          ) : null}
        </div>
      </div>
      {isModalOpen ? EditPortfolioModal : null}
    </Modal>
  )
}

export default MusicianPortfolioPopup
