import { Input } from 'antd'

const CustomInput = ({
  value,
  placeholder,
  onChange,
}: {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Input
      variant="borderless"
      className="text-white bg-transparent border-solid border-b-white border-t-transparent border-x-transparent border-b-[1px] rounded-none font-[Roboto] text-lg text-center placeholder:text-gray-300"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

export default CustomInput
