import MusicianPage from './Pages/MusicianPage/musicianPage'
import LandingPage from './Pages/LandingPage/landingPage'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <div className="lg:h-full">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="example-profile" element={<MusicianPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
