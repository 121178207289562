import { Done } from '@mui/icons-material'
import { Modal } from 'antd'
import React from 'react'
import AutoComplete from 'react-google-autocomplete'

const EditLocationModal = ({
  isModalOpen,
  handleOk,
}: {
  isModalOpen: boolean
  handleOk: (value: string[]) => void
}) => {
  const [location, setLocation] = React.useState<string>()

  return (
    <Modal centered open={isModalOpen} closable={false} footer={[]}>
      <div className="flex flex-col items-center">
        <AutoComplete
          className="text-input"
          apiKey="AIzaSyC1alBdKiy4EmQ40AbhmKZxwG7X1v2yDdA"
          onPlaceSelected={(place) => setLocation(place.formatted_address)}
          options={{
            types: ['(regions)'],
            componentRestrictions: { country: ['us', 'ps', 'il'] },
          }}
        />
        <button
          className="flex p-0 mt-2.5 justify-center items-center size-12 rounded-full bg-[#d9d9d9]"
          onClick={() => handleOk(location ? location.split(', ') : [])}
        >
          <Done />
        </button>
      </div>
    </Modal>
  )
}

export default EditLocationModal
