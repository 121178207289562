import React from 'react'

import MusicianPortfolioPopup from './musicianPortfolioPopup'
import getYouTubeID from 'get-youtube-id'

const MusicianPortfolio = ({ editMode }: { editMode: boolean }) => {
  const [videos, setVideos] = React.useState([
    'https://www.youtube.com/watch?v=xEmORQ5aaQc&ab_channel=KeyofSea',
    'https://www.youtube.com/watch?v=2HcOvqIUYN4&ab_channel=KeyofSea',
    'https://www.youtube.com/watch?v=go54QxACrNc&ab_channel=KeyofSea',
  ])
  const [audios, setAudios] = React.useState([
    'https://open.spotify.com/track/2BBiS0tSDesfiuhv1PEodP?si=ccdb43289465436d',
    'https://open.spotify.com/track/1X8pBfUNMacs0hoZRDhH2Z?si=f2ea4a7824fc4f47',
    'https://soundcloud.com/secret-service-862007284/light-again-demo-170?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
  ])
  const [popupOpen, setPopupOpen] = React.useState(false)

  return (
    <>
      <div
        className={`lg:pb-0 pb-[4vh] flex flex-col w-full px-[15px] rounded-lg overflow-y-auto max-h-[340px] bg-gradient-to-b from-[#202020] to-[#000000] mb-0 items-center cursor-pointer mx-4 lg:mx-0`}
        onClick={() => setPopupOpen(true)}
      >
        <p className="text-lg lg:text-xl py-[5px]">Portfolio</p>
        <div className="grid grid-cols-2 gap-4 mt-2">
          {videos.map((video, index) =>
            index < 4 ? (
              <img key={index} src={`https://img.youtube.com/vi/${getYouTubeID(video)}/default.jpg`} />
            ) : null,
          )}
        </div>
      </div>
      <MusicianPortfolioPopup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        videos={videos}
        audios={audios}
        setVideos={setVideos}
        setAudios={setAudios}
        editMode={editMode}
      />
    </>
  )
}

export default MusicianPortfolio
